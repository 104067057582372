<template>
    <div>
        <div class="w-12 h-12 flex flex-wrap justify-end cursor-pointer" :class="getToCenter ? 'content-center' : 'content-start'" @click="clickHamburguer" style="-webkit-flex-basis: 100%;">
            <inline-svg fill="black" :stroke="getColor" src="/svg/hamburger.svg" class="relative my-auto"/>
        </div>
        <div v-show="navVisible" class="h-screen w-screen absolute bg-white inset-0 overscroll-none overflow-y-auto z-60" >
            <header class="flex relative items-center px-4 md:px-4 lg:px-8 w-full z-10 h-12 border-b border-gray">
                    <div class="pt-1 relative w-1/3">
                        <button class="search z-40" @click="hideMenu">
                            <inline-svg class="transform origin-center w-5 h-5"
                                src="/svg/search_black.svg" />
                        </button>
                    </div>
                    <div class="w-1/3 flex justify-center" @click="goTo('home')">
                        <img width="80%" height="auto" src="/new_logos/2022-LA-Agency-Logotipo_Gold.png" class="absolute top-0 h-full object-cover"/>
                    </div>
                    <div class="flex justify-end relative w-1/3">
                        <button class="close z-40" @click="hideMenu">
                            <inline-svg class="transform origin-center rotate-45 w-5 h-5" fill="black"
                                src="/svg/plus.svg" />
                        </button>
                    </div>
            </header>
            <ul class="cursor-pointer text-md font-euclid tracking-widest m-4 mb-0 pb-4 transform z-40 leading-4 border-b border-gray menu">
                <li :class="textOptions.selected.selectedValue == 'Home' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerHome" @click="goTo('home')">
                        {{ $t('main.mainMenu.home') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'Listings' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerListings" @click="goToEgo('listings')">
                        {{ $t('main.mainMenu.listings') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'New Developments' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerNewDevelopments" @click="goTo('newDevelopments')">
                        {{ $t('main.mainMenu.newDevelopments') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'Collection Privée' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerCollection" @click="goTo('collection')">
                        {{ $t('main.mainMenu.collection') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'Services LA' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerServices" @click="goTo('services')">
                        {{ $t('main.mainMenu.services') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'This is LA' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerThisIsLa" @click="goTo('thisisla')">
                        {{ $t('main.mainMenu.thisisLA') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'Districts' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerDistricts" @click="goTo('districts')">
                        {{ $t('main.mainMenu.districts') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'Zoom us' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerZoom" @click="goTo('contact')">
                        {{ $t('main.mainMenu.zoom') }}
                    </a>
                </li>
                <li :class="textOptions.selected.selectedValue == 'Sell with us' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerSell" @click="goTo('sellwithus')">
                        {{ $t('main.mainMenu.sell') }}
                    </a>
                </li>
            </ul>
            <div class="lg:w-1/2 m-4 mt-0 pb-4 border-b border-gray cursor-pointer accordion">
                <div @click="openAccordion('city')">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="w-full flex cursor-pointer">
                            <label class="mt-4 w-full text-md tracking-widest font-medium text-black uppercase font-euclid cursor-pointer leading-5">
                                {{$t('footer.extended.city.title')}}
                            </label>    
                            <div class="mt-3 lg:mt-0 text-right relative cursor-pointer">
                                <label :id="'label_city'" class="text-black text-lg text-right cursor-pointer">></label>
                            </div>                              
                        </div>
                        <div :id="'description_city'" class="hidden w-11/12 mt-2 cursor-pointer accordion-description">
                            <ul class="text-md text-darkgray cursor-pointer leading-4">
                                <li v-for="item in  $t('footer.extended.city.cities')" :key="item" @click="listCity(item)" class="hover:text-bronze">{{item}}</li>
                            </ul>
                        </div>              
                    </div>
                </div>
                <div @click="openAccordion('lifestyle')">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="w-full flex cursor-pointer">
                            <label class="mt-2 w-full text-md tracking-widest font-medium text-black uppercase font-euclid cursor-pointer leading-5">
                                {{$t('footer.extended.lifestyle.title')}}
                            </label>    
                            <div class="mt-1 lg:mt-0 text-right relative cursor-pointer">
                                <label :id="'label_lifestyle'" class="text-black text-lg text-right cursor-pointer">></label>
                            </div>                              
                        </div>
                        <div :id="'description_lifestyle'" class="hidden w-11/12 mt-2 cursor-pointer accordion-description">
                            <ul class="text-md text-darkgray cursor-pointer leading-4">
                                <li v-for="(item, i) in  $t('footer.extended.lifestyle.types')" :key="item"  @click="listLifestyle(i+1)" class="hover:text-bronze">{{item}}</li>
                            </ul>
                        </div>              
                    </div>
                </div>
                <div @click="openAccordion('listings')">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="w-full flex cursor-pointer">
                            <label class="mt-2 w-full text-md tracking-widest font-medium text-black uppercase font-euclid cursor-pointer leading-5">
                                {{$t('footer.extended.type.title')}}
                            </label>    
                            <div class="mt-1 lg:mt-0 text-right relative cursor-pointer">
                                <label :id="'label_listings'" class="text-black text-lg text-right cursor-pointer">></label>
                            </div>                              
                        </div>
                        <div :id="'description_listings'" class="hidden w-11/12 mt-2 cursor-pointer accordion-description">
                            <ul class="text-md text-darkgray text cursor-pointer leading-4">
                                <li v-for="item in  $t('footer.extended.type.types')" @click="segment(item)" :key="item" class="hover:text-bronze">{{item}}</li>
                            </ul>
                        </div>              
                    </div>
                </div>
            </div>
            
            <div class="mb-4">
               <SocialsLink color="darkgray" />
            </div>
            <div class="text-darkgray text-sm text-center leading-3 uppercase">
                <a target="_blank" href="https://www.livroreclamacoes.pt/inicio">
                    {{$t('footer.extended.minimal.book')}}
                </a><br>
                <router-link :to="{ name: 'privacy', params: { lang: this.$i18n.locale }}">
                    {{ $t('footer.extended.minimal.privacy') }}
                </router-link>
            </div>
            <div class="text-darkgray text-sm text-center leading-3 uppercase mt-2 mb-4">
                <p>Copyright© {{year}} EXCLUSIVEFAMOUS, Lda. All rights reserved.</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.menu{
  --size: 13em;
  --max-size: 30vh;
  --top-odd: 50%;
  --top-even: 55%;
  --top-buffer: 35vh;
  
  --start-opacity: 0;
  --start-transform: translateY(-50%);
  --start-transition: transform 0s .7s, opacity .7s;
  --start-delay-transition: transform 0s .7s, opacity .6s .1s;
  
  --end-opacity: 1;
  --end-transform: translateY(-100%);
  --end-transition: transform .8s .2s, opacity .5s .1s;
  --end-delay-transition: transform .7s .3s, opacity .4s .2s;
}
.img{
  object-fit: cover;
  position: fixed;
  pointer-events: none;
  
  width: calc( 2/3 * var(--size) );
  height: var(--size);
  max-width: calc( 2/3 * var(--max-size) );
  max-height: var(--max-size);

  opacity: var(--start-opacity);
  transform: var(--start-transform);
  transition: var(--start-delay-transition);
  
  left:calc( -2/3 * var(--max-size) * 1.7 - 7vw);
  top:var(--top-odd);
}
.img ~ .img {
  left: calc( -2/3 * var(--max-size) - 7vw);
  top:calc( var(--top-odd) + var(--top-buffer) );
  transition: var(--start-transition);
}
li:nth-of-type(even) > .img{
  top:var(--top-even);
}
li:nth-of-type(even) > .img ~ .img{
  top:calc( var(--top-even) + var(--top-buffer) );
}

a:hover ~ .img {
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-delay-transition);
}
a:hover .img ~ .img {
  transition: var(--end-transition);
}

.active-menu .img{
  animation: slide-top .8s;
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-transition);
}

.menu:hover .active-menu .img {
  transition: var(--start-transition);
  opacity: var(--start-opacity);
  transform: var(--start-transform);
}

.menu:hover .active-menu a:hover + .img{
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-transition);
}
.menu:hover .active-menu a:hover + .img ~ .img{
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-transition);
}

@keyframes slide-top {
  0%{
    opacity: var(--start-opacity);
    transform: var(--start-transform);
  }
  100%{
    opacity: var(--end-opacity);
    transform: var(--end-transform);
  }
}

.img3{
  left: unset!important;
  top:100%!important;
  right: -1.5vw;
}

@media (min-width: 1280px) {
  .img3{
    right: calc(-7em + 3vw);
  }
}

</style>
<script>
import SocialsLink from "@/components/socials/SocialsLink.vue";

    export default {
        name: "HamburgerMenu",
        props:["color", "toCenter"],
        components: {
            SocialsLink
        },
        data(){
            return{
                year: new Date().getFullYear(),
                updateMenu: false,
                navVisible: false,
                textOptions:{
                    selected:{
                        selectedColor: "text-black font-medium active-menu",
                        selectedValue: 'Home'
                    },
                    nonSelected:{
                        nonSelectedColor: "text-opacity-50 text-gray"
                    }
                }
            }
        },
        computed:{
          getColor(){
            return this.color || "white";
          },
          getToCenter(){
            return this.toCenter || true;
          }
        },
        methods:{
            goToEgo(p){
                let isEnglish = this.$i18n.locale == 'en'
                if(p == 'listings'){
                if(isEnglish){
                    window.location.href = this.$ego_redirecter() + '/en-gb/properties'
                }else{
                    window.location.href = this.$ego_redirecter() + '/imoveis'
                }
                
                }
            },
            segment(s){
                this.hideMenu();
                let myLinkData = "segment:" + s
                this.$emit('segment-changed', s)
                this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
            },
            clickHamburguer(){
                this.navVisible = true;
                this.mountMenu()
                this.$emit('open-hamburguer')
                if(document.getElementById('mobile-menu-bottom')) document.getElementById('mobile-menu-bottom').classList?.add('hidden')
            },
            hideMenu(){
                this.navVisible = false;
                this.$emit('close-hamburguer')
                if(document.getElementById('mobile-menu-bottom')) document.getElementById('mobile-menu-bottom').classList?.remove('hidden')
            },
            mountMenu(){
                let routeName = this.$route.name
                
                this.cleanActiveMenus()
                switch(routeName){
                    case 'home':
                        {
                            let hash = this.$route.hash
                            if(hash == '')
                                this.textOptions.selected.selectedValue = 'Home'
                            else if(hash == '#newDevelopments_content')
                                this.textOptions.selected.selectedValue = 'New Developments'
                            else if(hash == '#collection_content')
                                this.textOptions.selected.selectedValue = 'Collection Privée'
                            else if(hash == '#districts_content')
                                this.textOptions.selected.selectedValue = 'Districts'
                            else if(hash == '#services_content')
                                this.textOptions.selected.selectedValue = 'Services LA'
                            else
                                this.textOptions.selected.selectedValue = 'Zoom us'


                            break;
                        }
                    case 'districts':
                        this.textOptions.selected.selectedValue = 'Districts'
                        break;
                    case 'ThisIsLA':
                        this.textOptions.selected.selectedValue = 'This is LA'
                        break;
                    case 'sellwithus':
                        this.textOptions.selected.selectedValue = 'Sell with us'
                        break;
                    case 'listings':
                        this.textOptions.selected.selectedValue = 'Listings'
                        break;
                    case 'listing':
                        this.textOptions.selected.selectedValue = 'Listings'
                        break;
                    default:
                }
            },
            goTo(target){
                this.cleanActiveMenus()
                this.hideMenu()
                switch(target){
                    case 'home':
                        this.$router.push({ name: target, params: { lang: this.$i18n.locale}})
                        this.textOptions.selected.selectedValue = 'Home'
                        break;
                    case 'newDevelopments':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#newDevelopments_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#newDevelopments_content'}})
                        
                        this.textOptions.selected.selectedValue = 'New Developments'
                        break;
                    case 'collection':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#collection_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#collection_content'}})
                        
                        this.textOptions.selected.selectedValue = 'Collection Privée'
                        break;
                    case 'listings':
                        this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: '0'}})
                        this.textOptions.selected.selectedValue = 'Listings'
                        break;
                    case 'districts':
                        this.$router.push({ name: 'districts', params: { lang: this.$i18n.locale, slug: 2 }})
                        this.textOptions.selected.selectedValue = 'Districts'
                        break;
                    case 'services':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#services_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#services_content'}})

                        this.textOptions.selected.selectedValue = 'Services LA'
                        break;
                    case 'thisisla':
                            this.$router.push({ name: 'ThisIsLA', params: { lang: this.$i18n.locale}})
                            this.textOptions.selected.selectedValue = 'This is LA'
                        break;
                    case 'contact':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#contact_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#contact_content'}})

                        this.textOptions.selected.selectedValue = 'Zoom us'
                        break;
                    case 'sellwithus':
                        this.$router.push({ name: 'sellwithus', params: { lang: this.$i18n.locale}})
                        this.textOptions.selected.selectedValue = 'Sell with us'
                        break;
                }
            },
            cleanActiveMenus(){
                //.classList.add("font-bold") MenuCollection
                this.textOptions.selected.selectedValue = 'Home'
                this.textOptions.selected.selectedValue = 'Collection Privée'
                this.textOptions.selected.selectedValue = 'Listings'
                this.textOptions.selected.selectedValue = 'Districts'
                this.textOptions.selected.selectedValue = 'Services LA'
                this.textOptions.selected.selectedValue = 'This is LA'
                this.textOptions.selected.selectedValue = 'Zoom us'
                this.textOptions.selected.selectedValue = 'Sell with us'
                
            },
            openAccordion(tag){
                const label = document.getElementById('label_'+tag);
                const open = label?.textContent == 'v';
                this.closeAllAccordions();
                if(label && !open){
                    label.textContent = 'v'
                    const desc = document.getElementById('description_'+tag);
                    desc.classList?.remove('hidden');

                    /*if(scrollTo){
                        const page = document.getElementById('thisisla_fullpagemodal').firstElementChild;
                        page.scrollTo(0, desc.offsetTop + 100 + desc.offsetHeight);
                    }*/
                }
            },
            closeAllAccordions(){
                document.getElementById('description_city').classList?.add('hidden')
                document.getElementById('label_city').textContent = '>';
                document.getElementById('description_lifestyle').classList?.add('hidden')
                document.getElementById('label_lifestyle').textContent = '>';
                document.getElementById('description_listings').classList?.add('hidden')
                document.getElementById('label_listings').textContent = '>';
            },
            listCity(city){
            let myLinkData = "city:" + city
            this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
            },
            listLifestyle(lifestyle){
                let myLinkData = "lifestyle:" + lifestyle
                this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: myLinkData }})
            },
            
        }
    }
</script>
