<template>
    <div id="mobile-header" class="w-screen flex items-center px-4 py-0 fixed z-50 md:py-10" :class="showGlassHeader ? 'glass-effect glass-fallback' : ''">
        
         <!-- begin::search -->
        <div class="pt-1 w-1/3">
            <button @click="openSearch()" class="search z-40">
                <inline-svg v-if="this.pageType == 'main'" class="transform origin-center w-5 h-5"
                src="/svg/search_white.svg" />
                <inline-svg v-else class="transform origin-center w-5 h-5"
                src="/svg/search_black.svg" />
            </button>
        </div>
        <!-- end::search -->

        <div id="searchBarContainer" class="w-0" :class="showSearch ? '' : 'hidden'">
            <SearchBar/>
        </div>
        
        <div class="hidden md:block  w-1/3 flex justify-center" @click="goToHome()">
            <img width="100%" height="auto" src="/new_logos/2022-LA-Agency-Logotipo_Gold.png" class="absolute top-0 left-0 h-full overflow-visible object-cover"/>
        </div>


        <!-- MOBILE IMAGE -->
        <div class="w-1/3 flex justify-center md:hidden" @click="goToHome()">
            <img width="80%" height="auto" src="/new_logos/2022-LA-Agency-Logotipo_Gold.png" class="absolute top-0 h-full object-cover"/>
        </div>
        <!-- END MOBILE IMAGE -->


        <!-- begin::Menu-icon -->
        <HamburgerMenuMobile v-if="this.pageType == 'main'" color="white" class=" w-1/3 flex justify-end"/>
        <HamburgerMenuMobile v-else color="black" class=" w-1/3 flex justify-end"/>
        <!-- end::Main-icon -->
    </div>
</template>
<script>
    import HamburgerMenuMobile from "@/components/core/HamburgerMenuMobile";
    import SearchBar from "@/components/core/SearchBar";
    //import LocaleSwitcher from '@/components/core/LocaleSwitcher'

    export default {
        name: "Header",
        props:["pageType"],
        components: {HamburgerMenuMobile, SearchBar},
        data: function(){
            return{
                showGlassHeader: false,
                showSearch: false
            }
        },
        created(){
            window.addEventListener('scroll', this.handleScroll2);
        },
        methods:{
            handleScroll2(){
                if(this.pageType == 'main'){
                    if(document.getElementById('cover_content')){
                        if(window.scrollY >= document.getElementById('cover_content').offsetHeight){
                            this.showGlassHeader = true
                        }
                        else{
                            this.showGlassHeader = false
                        }
                    }
                }
                else{
                    if(window.scrollY >= 90){
                        this.showGlassHeader = true
                    }
                    else{
                        this.showGlassHeader = false
                    }
                }
                if(this.showSearch == true){
                    this.openSearch();
                }
            },
            openSearch(){
                if(this.showSearch) this.showSearch = false;
                else this.showSearch = true;
            },
            goToHome(){
                this.$router.push({ name: 'home', params: { lang: this.$i18n.locale}});
            }
        }
    }
</script>