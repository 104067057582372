<template>
    <div>
        <div class="w-12 h-12 flex flex-wrap justify-center cursor-pointer" :class="getToCenter ? 'content-center' : 'content-start'" @click="clickHamburguer">
            <inline-svg fill="black" :stroke="getColor" src="/svg/hamburger.svg" class="relative"/>
        </div>
        <div v-show="navVisible" class="grid-bg h-screen w-screen absolute bg-black inset-0 overscroll-none" >
            <header class="flex relative items-center px-2 md:px-4 lg:px-8 w-full mt-10 z-10">
                    <div class="w-2/12 flex glass-fallback text-white">
                        <LocaleSwitcher fillColor="white"/>
                    </div>
                    <div class="flex justify-end relative w-full">
                        <button class="close z-40" @click="hideMenu">
                            <inline-svg class="transform origin-center rotate-45 w-3 h-3 md:w-5 md:h-5 lg:w-10 lg:h-10" fill="white"
                                src="/svg/plus.svg" />
                        </button>
                    </div>
            </header>
            <ul class="cursor-pointer text-sm md:text-md lg:text-lg uppercase font-blackMango tracking-widest absolute top-1/2 transform -translate-y-1/2 z-40 menu">
                <li :class="textOptions.selected.selectedValue == 'Home' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerHome" @click="goTo('home')">
                        {{ $t('main.mainMenu.home') }}
                    </a>
                    <img class="img" src="/img/menu/home.jpg">
                    <img class="img img2" src="/img/menu/home 2.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'Listings' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerListings" @click="goToEgo('listings')">
                        {{ $t('main.mainMenu.listings') }}
                    </a>
                    <img class="img" src="/img/menu/listings.jpg">
                    <img class="img img2" src="/img/menu/listings 2.jpg">
                    <img class="img img3" src="/img/menu/listings 3.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'New Developments' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerNewDevelopments" @click="goTo('newDevelopments')">
                        {{ $t('main.mainMenu.newDevelopments') }}
                    </a>
                    <img class="img" src="/img/menu/new developments.jpg">
                    <img class="img img2" src="/img/menu/new developments 2.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'Collection Privée' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerCollection" @click="goTo('collection')">
                        {{ $t('main.mainMenu.collection') }}
                    </a>
                    <img class="img" src="/img/menu/collection privée.jpg">
                    <img class="img img2" src="/img/menu/collection privée 2.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'Services LA' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerServices" @click="goTo('services')">
                        {{ $t('main.mainMenu.services') }}
                    </a>
                    <img class="img" src="/img/menu/services.jpg">
                    <img class="img img2" src="/img/menu/services 2.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'This is LA' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerThisIsLa" @click="goTo('thisisla')">
                        {{ $t('main.mainMenu.thisisLA') }}
                    </a>
                    <img class="img" src="/img/menu/this is la.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'Districts' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerDistricts" @click="goTo('districts')">
                        {{ $t('main.mainMenu.districts') }}
                    </a>
                    <img class="img" src="/img/menu/districts.jpg">
                    <img class="img img2" src="/img/menu/districts 2.jpg">
                    <img class="img img3" src="/img/menu/districts 3.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'Zoom us' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerZoom" @click="goTo('contact')">
                        {{ $t('main.mainMenu.zoom') }}
                    </a>
                    <img class="img" src="/img/menu/zoom us.jpg">
                </li>
                <li :class="textOptions.selected.selectedValue == 'Sell with us' ? textOptions.selected.selectedColor : textOptions.nonSelected.nonSelectedColor" class="hover:text-bronze">
                    <a id="HamburgerSell" @click="goTo('sellwithus')">
                        {{ $t('main.mainMenu.sell') }}
                    </a>
                    <img class="img" src="/img/menu/sell with us.jpg">
                    <img class="img img2" src="/img/menu/sell with us 2.jpg">
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
a{
  font-size: calc(2.2rem + 1.5vw);
}
@media screen and (min-width: 480px){
a{
  font-size: calc(2.2rem + 1vw);
}
}
.menu{
  left: 45%;
  padding-right: 1rem;
  
  --size: 13em;
  --max-size: 30vh;
  --top-odd: 50%;
  --top-even: 55%;
  --top-buffer: 35vh;
  
  --start-opacity: 0;
  --start-transform: translateY(-50%);
  --start-transition: transform 0s .7s, opacity .7s;
  --start-delay-transition: transform 0s .7s, opacity .6s .1s;
  
  --end-opacity: 1;
  --end-transform: translateY(-100%);
  --end-transition: transform .8s .2s, opacity .5s .1s;
  --end-delay-transition: transform .7s .3s, opacity .4s .2s;
}
.img{
  object-fit: cover;
  position: fixed;
  pointer-events: none;
  
  width: calc( 2/3 * var(--size) );
  height: var(--size);
  max-width: calc( 2/3 * var(--max-size) );
  max-height: var(--max-size);

  opacity: var(--start-opacity);
  transform: var(--start-transform);
  transition: var(--start-delay-transition);
  
  left:calc( -2/3 * var(--max-size) * 1.7 - 7vw);
  top:var(--top-odd);
}
.img ~ .img {
  left: calc( -2/3 * var(--max-size) - 7vw);
  top:calc( var(--top-odd) + var(--top-buffer) );
  transition: var(--start-transition);
}
li:nth-of-type(even) > .img{
  top:var(--top-even);
}
li:nth-of-type(even) > .img ~ .img{
  top:calc( var(--top-even) + var(--top-buffer) );
}

a:hover ~ .img {
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-delay-transition);
}
a:hover .img ~ .img {
  transition: var(--end-transition);
}

.active-menu .img{
  animation: slide-top .8s;
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-transition);
}

.menu:hover .active-menu .img {
  transition: var(--start-transition);
  opacity: var(--start-opacity);
  transform: var(--start-transform);
}

.menu:hover .active-menu a:hover + .img{
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-transition);
}
.menu:hover .active-menu a:hover + .img ~ .img{
  opacity: var(--end-opacity);
  transform: var(--end-transform);
  transition: var(--end-transition);
}

@keyframes slide-top {
  0%{
    opacity: var(--start-opacity);
    transform: var(--start-transform);
  }
  100%{
    opacity: var(--end-opacity);
    transform: var(--end-transform);
  }
}

.img3{
  left: unset!important;
  top:100%!important;
  right: -1.5vw;
}

@media (min-width: 1280px) {
  .img3{
    right: calc(-7em + 3vw);
  }
}

</style>
<script>
    import LocaleSwitcher from '@/components/core/LocaleSwitcher'

    export default {
        name: "HamburgerMenu",
        components:{
          LocaleSwitcher,
        },
        props:["color", "toCenter"],
        data(){
            return{
                updateMenu: false,
                navVisible: false,
                textOptions:{
                    selected:{
                        selectedColor: "text-white active-menu",
                        selectedValue: 'Home'
                    },
                    nonSelected:{
                        nonSelectedColor: "text-opacity-50 text-white"
                    }
                }
            }
        },
        computed:{
          getColor(){
            return this.color || "white";
          },
          getToCenter(){
            return this.toCenter;
          }
        },
        methods:{
            goToEgo(p){
                let isEnglish = this.$i18n.locale == 'en'
                if(p == 'listings'){
                if(isEnglish){
                    window.location.href = this.$ego_redirecter() + '/en-gb/properties'
                }else{
                    window.location.href = this.$ego_redirecter() + '/imoveis'
                }
                
                }
            },
            clickHamburguer(){
                this.navVisible = true;
                this.mountMenu()
                this.$emit('open-hamburguer')
            },
            hideMenu(){
                this.navVisible = false;
                this.$emit('close-hamburguer')
            },
            mountMenu(){
                let routeName = this.$route.name
                
                this.cleanActiveMenus()
                switch(routeName){
                    case 'home':
                        {
                            let hash = this.$route.hash
                            if(hash == '')
                                this.textOptions.selected.selectedValue = 'Home'
                            else if(hash == '#newDevelopments_content')
                                this.textOptions.selected.selectedValue = 'New Developments'
                            else if(hash == '#collection_content')
                                this.textOptions.selected.selectedValue = 'Collection Privée'
                            else if(hash == '#districts_content')
                                this.textOptions.selected.selectedValue = 'Districts'
                            else if(hash == '#services_content')
                                this.textOptions.selected.selectedValue = 'Services LA'
                            else
                                this.textOptions.selected.selectedValue = 'Zoom us'


                            break;
                        }
                    case 'districts':
                        this.textOptions.selected.selectedValue = 'Districts'
                        break;
                    case 'ThisIsLA':
                        this.textOptions.selected.selectedValue = 'This is LA'
                        break;
                    case 'sellwithus':
                        this.textOptions.selected.selectedValue = 'Sell with us'
                        break;
                    case 'listings':
                        this.textOptions.selected.selectedValue = 'Listings'
                        break;
                    case 'listing':
                        this.textOptions.selected.selectedValue = 'Listings'
                        break;
                    default:
                }
            },
            goTo(target){
                this.cleanActiveMenus()
                this.hideMenu()
                switch(target){
                    case 'home':
                        this.$router.push({ name: target, params: { lang: this.$i18n.locale}})
                        this.textOptions.selected.selectedValue = 'Home'
                        break;
                    case 'newDevelopments':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#newDevelopments_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#newDevelopments_content'}})
                        
                        this.textOptions.selected.selectedValue = 'New Developments'
                        break;
                    case 'collection':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#collection_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#collection_content'}})
                        
                        this.textOptions.selected.selectedValue = 'Collection Privée'
                        break;
                    case 'listings':
                        this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: '0'}})
                        this.textOptions.selected.selectedValue = 'Listings'
                        break;
                    case 'districts':
                        this.$router.push({ name: 'districts', params: { lang: this.$i18n.locale, slug: 2 }})
                        this.textOptions.selected.selectedValue = 'Districts'
                        break;
                    case 'services':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#services_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#services_content'}})

                        this.textOptions.selected.selectedValue = 'Services LA'
                        break;
                    case 'thisisla':
                            this.$router.push({ name: 'ThisIsLA', params: { lang: this.$i18n.locale}})
                            this.textOptions.selected.selectedValue = 'This is LA'
                        break;
                    case 'contact':
                        if(this.updateMenu == false)
                            this.$router.push({ name: 'home', hash: '#contact_content', params: { lang: this.$i18n.locale}})
                        else
                            this.$router.push({ name: 'home', params: { lang: this.$i18n.locale, chunk: '#contact_content'}})

                        this.textOptions.selected.selectedValue = 'Zoom us'
                        break;
                    case 'sellwithus':
                        this.$router.push({ name: 'sellwithus', params: { lang: this.$i18n.locale}})
                        this.textOptions.selected.selectedValue = 'Sell with us'
                        break;
                }
            },
            cleanActiveMenus(){
                //.classList.add("font-bold") MenuCollection
                this.textOptions.selected.selectedValue = 'Home'
                this.textOptions.selected.selectedValue = 'Collection Privée'
                this.textOptions.selected.selectedValue = 'Listings'
                this.textOptions.selected.selectedValue = 'Districts'
                this.textOptions.selected.selectedValue = 'Services LA'
                this.textOptions.selected.selectedValue = 'This is LA'
                this.textOptions.selected.selectedValue = 'Zoom us'
                this.textOptions.selected.selectedValue = 'Sell with us'
                
            }
            
        }
    }
</script>
